@font-face {
  font-family: 'Druk Wide Medium';
  src: url('./fonts/druk-wide/druk-wide-medium.otf') format('opentype');
}

@font-face {
  font-family: 'Trade Gothic LT STD';
  src: url('./fonts/trade-gothic/trade-gothic-lt-std.otf') format('opentype');
}

@font-face {
  font-family: 'Trade Gothic LT STD';
  font-weight: bold;
  src: url('./fonts/trade-gothic/trade-gothic-lt-std-bd-2.otf')
    format('opentype');
}

@font-face {
  font-family: 'Trade Gothic LT STD';
  font-weight: bold;
  font-stretch: condensed;
  src: url('./fonts/trade-gothic/trade-gothic-lt-std-bd-cn-20.otf')
    format('opentype');
}

@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 900;
  src: url('./fonts/fraktionmono/FraktionMono-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 500;
  src: url('./fonts/fraktionmono/FraktionMono-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Fraktion Mono';
  src: url('./fonts/fraktionmono/FraktionMono-Regular.otf') format('opentype');
}

/* --- Customizations ---*/
body {
  background-color: #f5f5f5;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App > .MuiContainer-root {
  flex: 1;
}

/* --- Animations ---*/
.box__inner--hovered--start {
  transform: translate(-16px, 0);
}
.box__inner--hovered--end {
  transform: translate(0, 0);
  transition: all 1s ease;
}

@keyframes slider-zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.10, 1.10);
  }
}

/* --- Social icons --- */
#linkedin-icon {
  width: 24px;
  height: 24px;
  background-image: url(./img/linkedin_icon.png);
  background-repeat: no-repeat;
  background-position: center;
}

#instagram-icon {
  width: 24px;
  height: 24px;
  background-image: url(./img/instagram_icon.png);
  background-repeat: no-repeat;
  background-position: center;
}

#youtube-icon {
  width: 24px;
  height: 24px;
  background-image: url(./img/youtube_icon.png);
  background-repeat: no-repeat;
  background-position: center;
}

#x-icon {
  width: 24px;
  height: 24px;
  background-image: url(./img/x_icon.png);
  background-repeat: no-repeat;
  background-position: center;
}
